<template>
    <div class="verify_account">
        <div class="area_content">
            <div class="logo">
                <img src="@/assets/images/logo2.svg" alt="Logo">
            </div>
            <h2>Sign in to your account</h2>
            <div class="g_sign mt-5 mb-5">
                <google-login :callback="googleAuthCallback" prompt auto-login>
                    <button type="button" :disabled="accountCreateLoader || googleAuthLoader" class="primary_btn">
                        <template v-if="googleAuthLoader">
                            <i class="fa fa-spin fa-spinner prefix"></i><span>Processing</span>
                        </template>
                        <template v-else>
                            <i class="fab fa-google prefix"></i><span>Continue With Google</span>
                        </template>
                    </button>
                </google-login>
            </div>
            <h5><span>Or sign in with your email</span></h5>
            <Form class="verify_form" @submit="handleChangeEmail" v-slot="{ errors }">
                <div class="setting_wpr">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">E-mail</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.email }">
                                <Field autocomplete="off" type="email" name="email" v-model="form.email" placeholder="johndoe@thrivecoach.io" rules="required|email" />
                            </div>
                            <ErrorMessage name="email" class="text-danger" />
                        </div>
                    </div>
                    <div class="create_btn mt-3">
                        <button class="primary_btn" :disabled="accountCreateLoader || googleAuthLoader">
                            <template v-if="accountCreateLoader && !googleAuthLoader">
                                <i class="fa fa-spin fa-spinner"></i>&nbsp; Processing
                            </template>
                            <template v-else>
                                <span>CONTINUE WITH EMAIL</span>
                            </template>
                        </button>
                    </div>
                </div>
            </Form>
            <div class="login_btn">
                Don't have an account yet? <router-link :to="{ name: 'Register' }">Get started here</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { OAuth2Client } from 'google-auth-library'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    const client  = new OAuth2Client({
                        clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
                        clientSecret: process.env.VUE_APP_GOOGLE_CLIENT_SECRET,
                        redirectUri: process.env.VUE_APP_GOOGLE_REDIRECT_URI
                    });

    async function verifyCode (code) {
        let { tokens } = await client.getToken(code);

        client.setCredentials({ access_token: tokens.access_token });

        const userinfo = await client.request({ url: 'https://www.googleapis.com/oauth2/v3/userinfo' });

        return userinfo.data
    }

    export default {
        name: 'Change Account Email',

        data () {
            return {
                form: {
                    email: '',
                },
                googleAuthLoader: false,
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        computed: mapState({
            accountCreateLoader: state => state.registerModule.accountCreateLoader,
            acountDetails: state => state.registerModule.acountDetails,
        }),

        mounted () {
            const vm = this;

            vm.getAccountDetail(vm.$route.params.token).then((result) => {
                if (!result) {
                    const options = Helper.swalWarningOptions('Oops!', 'The account token is invalid or expired');

                    Swal.fire(options).then((ev) => {
                        if (ev.isConfirmed) {
                            vm.$router.push({ name: 'Register' });
                        }
                    });
                }
            });
        },

        methods: {
            ...mapActions({
                changeAccountEmail: 'registerModule/changeAccountEmail',
                getAccountDetail: 'registerModule/getAccountDetail',
            }),

            handleChangeEmail (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                const referralToken = vm.$cookies.get('referral_token');

                params.setFieldError = setFieldError;
                params.token = vm.$route.params.token;

                if (vm.$route.query && vm.$route.query.plan) {
                    params.plan = vm.$route.query.plan;
                }

                if (referralToken) {
                    params.referral_token = referralToken;
                }

                vm.changeAccountEmail(params).then((result) => {
                    if (result) {
                        vm.$router.push({name: 'VerifyAccount', params: { token: result.token }, query: { plan: vm.$route.query.plan }});
                    }
                });
            },

            googleAuthCallback (resp) {
                const vm = this;

                vm.googleAuthLoader = true;

                verifyCode(resp.code).then((user) => {
                    const referralToken = vm.$cookies.get('referral_token');
                    const params = {
                        first_name: user.given_name,
                        last_name: user.family_name,
                        email: user.email,
                        google_auth: true,
                        token: vm.$route.params.token,
                    };

                    if (vm.$route.query && vm.$route.query.plan) {
                        params.plan = vm.$route.query.plan;
                    }

                    if (referralToken) {
                        params.referral_token = referralToken;
                    }

                    vm.changeAccountEmail(params).then((result) => {
                        if (result) {
                            vm.$router.push({name: 'VerifyAccount', params: { token: result.token }, query: { plan: vm.$route.query.plan }});
                        }

                        vm.googleAuthLoader = false;
                    });
                }).catch((error) => {
                    const options = Helper.swalWarningOptions('Oops!', 'Looks like something went wrong, please try after some time.');

                    Swal.fire(options);
                    vm.googleAuthLoader = false;
                })
            },
        },
    }
</script>

<style scoped>
    .verify_account {
        height: 100vh;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .verify_account .area_content {
        max-width: 500px;
        width: 100%;
        text-align: left;
    }

    .verify_account .area_content .logo {
        margin: 0 0 40px;
    }

    .verify_account .area_content .logo img {
        max-width: 200px;
        height: auto;
        width: 100%;
    }

    .verify_account h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        color: #121525;
        margin-bottom: 10px;
    }

    .verify_account h5 {
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 500;
        max-width: 350px;
    }

    .verify_account .verify_form {
        border: 1px solid #e9e9e9;
        background: #f5f5f5;
        border-radius: 8px;
        padding: 20px 40px 45px 40px;
        margin-top: 30px;
    }

    .verify_account .verify_form .field_wpr {
        background: #fff;
    }

    .verify_account .more_action {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
    }

    .verify_account .more_action li {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        cursor: pointer;
    }

    .verify_account .more_action li i {
        margin-right: 7px;
    }

    .verify_account .primary_btn {
        position: relative;
        height: 50px;
        cursor: pointer;
    }

    .verify_account .primary_btn span {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }

    .verify_account .primary_btn .suffix {
        position: absolute;
        right: 70px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
    }

    .verify_account h5 {
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 500;
        text-align: center;
        position: relative;
        max-width: 100%;
    }

    .verify_account h5:after {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        width: 100%;
        border-bottom: 1px solid #d9d9d9;
        z-index: 0;
    }

    .verify_account h5 span {
        position: relative;
        z-index: 1;
        padding: 0 15px;
        background: #fafaf5;
    }

    .verify_account .login_btn {
        display: flex;
        justify-content: center;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
        padding: 20px 0;
    }

    .verify_account .login_btn a {
        text-decoration: none;
        color: #2f7eed;
        padding: 0 5px;
    }

    .verify_account .primary_btn .prefix {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
    }

    .verify_account .primary_btn .fa-spinner.prefix {
        position: absolute;
        left: 30px;
        top: 30%;
        transform: translateY(-50%);
    }

    :deep(.g-btn-wrapper) {
        width: 100%;
    }
</style>
